<template>

	<div v-show="!loaded" class="justify-content-center text-center flex-wrap">
		<ProgressSpinner/>
		<h5>Loading Data ...</h5>
	</div>

	<div v-show="loaded" class="flex flex-wrap">
        <div @click="setActivePartner(partner.id, partner.partner_type_id)" class="card m-4" style="height:250px;width: 300px;" v-for="partner in partners" :key="partner.id">
            <img v-bind:src="'https://zltoexchange.s3.amazonaws.com/media/' + partner.merchant_logo" style="height: 150px;width:250px;" />
            <p class="text-center m-4">
                {{ partner.name }}
            </p>
        </div>
	</div>
</template>

<script>
import EventBus from '@/AppEventBus';

import PartnerService from '../service/PartnerService';

import AuthEventBus from "../common/AuthEventBus";

export default {
	data() {
		return {
            partnerService:null,
            partners: [],
        }
    },
	mounted() {


		this.getPartners()


		this.themeChangeListener = (event) => {
            if (event.dark)
                this.applyDarkTheme();
            else
                this.applyLightTheme();
        };
        EventBus.on('change-theme', this.themeChangeListener);

		if (this.isDarkTheme()) {
            this.applyDarkTheme();
        }
        else {
            this.applyLightTheme();
        }
	},
	computed:{
	},

    watch: {
	},
	beforeUnmount() {
        EventBus.off('change-theme', this.themeChangeListener );
    },
	created() {
		this.partnerService = new PartnerService();


	},
	methods: {
		
		getPartners(){
            this.partnerService.getUserPartners().then(data => {
            console.log('@@@ partners', data.data)
            this.partners = data.data
            this.loaded = true;
        },
			error => {
				this.content = 
				(error.response && error.response.data && error.response.data.message) ||
				error.message ||
				error.toString();
				
				if (error.response && error.response.status === 403) {
					console.log('@@@@, error', error)
					AuthEventBus.dispatch("logout");
				}
			})
		},

        setActivePartner(value, type){
            localStorage.setItem('partner', value)
			localStorage.setItem('partner_type', type)
			console.log(type)
			if(type == 1){
				this.$router.push('/dashboard')
			}
			else{
				this.$router.push('/spenddashboard')

			}
            
        },

		isDarkTheme() {
            return this.$appState.darkTheme === true;
        },
		applyLightTheme() {
			this.lineOptions = {
				plugins: {
					legend: {
						labels: {
							color: '#495057'
						}
					}
				},
				scales: {
					x: {
						ticks: {
							color: '#495057'
						},
						grid: {
							color:  '#ebedef',
						}
					},
					y: {
						ticks: {
							color: '#495057'
						},
						grid: {
							color:  '#ebedef',
						}
					},
				}
			};
			this.pieOptions = {
				plugins: {
					legend: {
						labels: {
							color: '#495057'
						}
					}
				}
			};
            this.horizontalOptions = {
                indexAxis: 'y',
                plugins: {
                },
                scales: {
                    x: {
                        ticks: {
                            color: '#495057'
                        },
                        grid: {
                            color: '#ebedef'
                        }
                    },
                    y: {
                        ticks: {
                            color: '#495057'
						}
					}
				}
		};
		},
		applyDarkTheme() {
			this.lineOptions = {
				plugins: {
					legend: {
						labels: {
							color: '#ebedef'
						}
					}
				},
				scales: {
					x: {
						ticks: {
							color: '#ebedef'
						},
						grid: {
							color:  'rgba(160, 167, 181, .3)',
						}
					},
					y: {
						ticks: {
							color: '#ebedef'
						},
						grid: {
							color:  'rgba(160, 167, 181, .3)',
						}
					},
				}
			};
		}
	}
}
</script>